<script setup lang="ts">
import { onClickOutside, useFocus, useMagicKeys } from '@vueuse/core'
import { getProductPermalink, getSearchPermalink } from '~/lib'
const localePath = useLocalePath()

const props = withDefaults(
  defineProps<{
    displayTotal?: number
  }>(),
  { displayTotal: 5, order: 'is-set' },
)

const { searchTerm, search, getProducts, getTotal, loading } = useProductSearchSuggest()

// True when the search bar is active and user can type in the search field
const active = ref(false)

// Reference to the search container
const searchContainer = ref(null)
const searchInput = ref()

watch(active, (value) => {
  const { focused } = useFocus(searchInput)

  focused.value = value
})

// String the user has typed in the search field
const typingQuery = ref('')

watch(typingQuery, (value) => {
  if (value.length >= 3) {
    performSuggestSearch(value)
  }
})

// Defer the search request to prevent the search from being triggered too after typing
const performSuggestSearch = useDebounceFn((value) => {
  searchTerm.value = value
  search({ order: 'is-set' })
}, 300)

// Suggest results will only be shown, when the search bar is active and the user has typed more than three characters in the search field
const showSuggest = computed(() => {
  return typingQuery.value.length >= 3 && active.value
})

if (process.client) {
  onClickOutside(searchContainer, () => {
    active.value = false
  })
}

const { enter } = useMagicKeys({ target: searchInput })
const { push } = useRouter()

watch(enter, (value) => {
  if (!value) return
  active.value = false
  push(`${localePath(getSearchPermalink())}?query=${typingQuery.value}`)
})

const results = computed(() => {
  return (
    getProducts.value?.slice(0, props.displayTotal).map((product) => ({
      id: product.id,
      link: {
        text: product.translated.name || '',
        url: localePath(getProductPermalink(product)),
      },
    })) || []
  )
})

const ceva = () => {
  if (!typingQuery.value) return
  active.value = false
  push(`${localePath(getSearchPermalink())}?query=${typingQuery.value}`)
}
</script>

<template>
  <div ref="searchContainer" v-animate="{ animation: 'vertical-reveal' }" class="wrapper">
    <div class="results-wrapper">
      <SearchField
        ref="searchInput"
        v-model="typingQuery"
        :placeholder="$t('shop.listingPage.search.label')"
        @search="ceva"
        @click="active = true"
      />
      <div v-if="showSuggest" class="results">
        <LinkList :items="results" />
        <div v-if="loading" class="text-small">{{ $t('shop.listingPage.search.loading') }}</div>
        <div v-else :style="{ textAlign: 'right' }">
          <Button
            v-if="getTotal > 1"
            secondary
            slide
            :to="`${localePath(getSearchPermalink())}?query=${typingQuery}&order=is-set`"
            @click=";[(active = false)]"
          >
            {{ $t('shop.listingPage.search.allResults') }}
          </Button>
          <div v-else>
            <span class="text-small">{{ $t('shop.listingPage.search.noResults') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.wrapper {
  .results-wrapper {
    position: relative;
    width: 100%;
    .results {
      position: absolute;
      box-shadow: var(--shadow);
      background-color: var(--c-white);
      padding: 20px;
      width: 100%;

      z-index: 99;
    }

    .link-list {
      margin-bottom: 24px;
    }
    .button {
      width: 100%;
      @include breakpoint(ts) {
        max-width: 400px;
      }
    }
  }
}
</style>
